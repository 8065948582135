export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqx9z6qw9ekttwp2d2h9tvq36ucdlp985035ds9qhmxm';

export const dAppName = 'middleman.nft';

export const verified = [
  'AERMES-ac9886',
  'BDGA-a05446',
  'DRIFTERS-efd96c',
  'EAPES-8f3c1f',
  'EGLDHUNTER-9abc5c',
  'GNGV1-b47227',
  'GNOGEN-8156fb',
  'GNOGONS-73222b',
  'GUARDIAN-3d6635',
  'INNOVATOR-fca3a7',
  'LIGHTNINGB-496265',
  'MAIARPUNKS-3db7e8',
  'MANY-39af2c',
  'PASS-1c7c6a',
  'PITTZ-1a4c2d',
  'PRE-807532',
  'QUACK-f01e02',
  'RAPTOR-28e21c',
  'REALM-579543',
  'SRC-27d8ff',
  'SYMB-a060b0',
  'VALIDATORS-e7e287',
  'WARRIORZ-2f0986',
  'WWWINE-5a5331'
];
