import React from 'react';
import { DappUI, DappProvider } from '@elrondnetwork/dapp-core';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import PageNotFound from 'pages/PageNotFound';
import UnlockRoute from 'pages/UnlockPage';
import { routeNames } from 'routes';
import routes from 'routes';
import '@elrondnetwork/dapp-core/build/index.css';

const environment = 'mainnet';

const {
  //TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
  //DappCorePages: { UnlockPage }
} = DappUI;

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={environment}
        customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
        completedTransactionsDelay={200}
      >
        <Layout>
          {/* <TransactionsToastList
            shouldRenderDefaultCss={true}
            className='text-black'
          /> */}
          <NotificationModal />
          <SignTransactionsModals
            shouldRenderDefaultCss={false}
            className='flex flex-row text-transparent opacity-0 w-100 h-100 absolute top-0 backdrop-hue-rotate-180 z-0'
          />
          <Routes>
            <Route
              path={routeNames.unlock}
              element={
                <UnlockRoute //loginRoute={routeNames.dashboard}
                />
              }
            />
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={<route.component />}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  );
};

export default App;
